// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-formularz-zapytaniowy-js": () => import("./../../../src/pages/formularz-zapytaniowy.js" /* webpackChunkName: "component---src-pages-formularz-zapytaniowy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konfigurator-pudelka-js": () => import("./../../../src/pages/konfigurator-pudelka.js" /* webpackChunkName: "component---src-pages-konfigurator-pudelka-js" */),
  "component---src-pages-podstrony-js": () => import("./../../../src/pages/podstrony.js" /* webpackChunkName: "component---src-pages-podstrony-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

